import { LitElement, html, css } from "lit";
import { unsafeCSS } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";
import { styleMap } from "lit/directives/style-map.js"; // Import styleMap
import { ref, createRef, Ref } from "lit/directives/ref.js"; // Import ref directives
import baseStyles from "../baseStyles.css?raw";

const FALLBACK_PLACEHOLDER_SRC = "https://placehold.co/800x800?text=Not+Available&font=roboto";

@customElement("bui-picture")
export class BuiPicture extends LitElement {
  @property({ type: String }) brand = "Rolex";
  @property({ type: String }) model = "Submariner";
  @property({ type: Boolean, attribute: "img-preload" }) imgPreload = false;
  @property({ type: String }) imgCDN = "https://images.bobswatches.com";
  @property({ type: String, attribute: "subdomain" }) imgSubdomain = "";
  @property({ type: Number, attribute: "img-effort" }) effort = 3;
  @property({ type: Number, attribute: "img-quality" }) quality = 40;
  @property({ type: String, attribute: "img-fit" }) imgFit = "contain";
  @property({ type: String, attribute: "img-sharpness" }) sh = "true";
  @property({ type: String, attribute: "img-width" }) imgWidth = "";
  @property({ type: String, attribute: "img-height" }) imgHeight = "";
  @property({ type: String, attribute: "img-loading" }) imgLoading: "eager" | "lazy" = "lazy";
  @property({ type: String, attribute: "img-priority" }) imgPriority = "low"; // Note: fetchpriority values are 'high', 'low', 'auto'
  @property({ type: String, attribute: "img-src" }) imgSrc = "/images/Used-Rolex-Submariner-126610-Black-Chromalight-Dial-SKU167399.jpg";
  @property({ type: String, attribute: "img-alt" }) imgAlt = "";
  @property({ type: String, attribute: "img-ratio" }) imgRatio = "auto";
  @property({ type: String, attribute: "img-dprs" }) imgDprs = "1,1.5,1.8,2,3,4";
  @property({ type: Boolean }) fluid = false;
  @state() private _imageLoadFailed = false;

  private resizeObserver: ResizeObserver | null = null;
  private _currentSrcChecked: string | null = null; // Track src for checks
  private imgRef: Ref<HTMLImageElement> = createRef(); // Create a ref for the img element

  static styles = [
    unsafeCSS(baseStyles),
    css`
      :host {
        display: block;
        width: fit-content;
        position: relative;
      }

      :host([fluid]) {
        height: 100%;
        width: 100%;
      }

      /* Apply common styles to picture and potential fallback img */
      picture,
      img {
        display: flex;
        align-self: stretch;
        width: 100%;
      }

      /* Apply object-fit and aspect-ratio via CSS custom properties */
      img {
        object-fit: var(--img-fit, contain);
        aspect-ratio: var(--img-ratio, auto);
      }

      /* Specific style for fallback */
      .fallback-img {
        object-fit: cover; /* Overrides --img-fit for fallback */
      }
    `,
  ];

  connectedCallback(): void {
    super.connectedCallback();
    if (this.fluid) {
      this.observeSize();
    }
    if (this.imgPreload) {
      this.preloadImages();
    }
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  private preloadImages() {
    const baseSrc = this.imgCDN + this.imgSrc;
    const params = this.buildImageParams(); // Use helper
    // Calculate device DPR with one decimal precision
    const dpr = Math.round((window.devicePixelRatio || 1) * 10) / 10;
    // Use the actual device pixel ratio instead of forcing it to 1 or 2
    const preloadDpr = dpr;
    const formats = ["avif"]; // Consider preloading webp too?

    formats.forEach((format) => {
      const preloadSrc = `${baseSrc}${params}&fmt=${format}&dpr=${preloadDpr}`;
      if (!document.querySelector(`link[rel="preload"][href="${preloadSrc}"]`)) {
        const link = document.createElement("link");
        link.rel = "preload";
        link.as = "image";
        link.href = preloadSrc;
        // Adding type attribute can help with resource prioritization
        link.type = `image/${format}`;
        document.head.appendChild(link);
      }
    });
  }

  private observeSize() {
    let resizeTimeout: number;
    this.resizeObserver = new ResizeObserver((entries) => {
      window.clearTimeout(resizeTimeout);
      resizeTimeout = window.setTimeout(() => {
        for (const entry of entries) {
          const { width, height } = entry.contentRect;
          // Update properties only if they change significantly to avoid loops
          const newWidth = Math.round(width).toString();
          const newHeight = Math.round(height).toString();
          if (newWidth !== this.imgWidth) {
            this.imgWidth = newWidth;
          }
          if (newHeight !== this.imgHeight) {
            this.imgHeight = newHeight;
          }
        }
      }, 150); // Slightly shorter debounce
    });
    this.resizeObserver.observe(this);
  }

  override updated(changedProperties: Map<string | symbol, unknown>) {
    const sourceProps: (keyof BuiPicture)[] = ["imgSrc", "imgCDN", "imgWidth", "imgHeight", "effort", "quality", "imgFit", "sh", "imgSubdomain"];
    let sourceChanged = false;
    for (const prop of sourceProps) {
      if (changedProperties.has(prop)) {
        sourceChanged = true;
        break;
      }
    }

    // Use the ref to get the current image element
    const imgElement = this.imgRef.value;
    const currentActualSrc = imgElement?.currentSrc || imgElement?.src;

    /**
     * Reset failure state if the source properties have changed OR
     * if the actual rendered image source is different from the one we last checked
     * (handles cases where the browser picks a different source from srcset)
     */
    if (sourceChanged || (currentActualSrc && currentActualSrc !== this._currentSrcChecked && currentActualSrc !== FALLBACK_PLACEHOLDER_SRC)) {
      // Only reset if it's currently marked as failed
      if (this._imageLoadFailed) {
        console.log("Image source changed or retrying, resetting failure state.");
        this._imageLoadFailed = false; // This state change schedules the needed update
      }
      this._currentSrcChecked = null; // Force a re-check below
    }

    // Check image load only if we have an image element, haven't successfully checked the current source,
    // and we are not currently rendering the fallback.
    if (imgElement && currentActualSrc && currentActualSrc !== this._currentSrcChecked && !this._imageLoadFailed) {
      this._checkImageLoad(imgElement, currentActualSrc);
    }
  }

  private _checkImageLoad(imgElement: HTMLImageElement, srcToCheck: string) {
    this._currentSrcChecked = srcToCheck; // Mark this src as being checked

    if (imgElement.complete) {
      const failed = imgElement.naturalWidth === 0;
      Promise.resolve().then(() => {
        if (this._imageLoadFailed !== failed && this._currentSrcChecked === srcToCheck) {
          this._imageLoadFailed = failed;
        }
      });
    } else {
      let loadHandler: () => void;
      let errorHandler: (ev: Event) => void;
      let timeoutId: number | undefined; // Keep variable declaration

      const removeListeners = () => {
        imgElement.removeEventListener("load", loadHandler);
        imgElement.removeEventListener("error", errorHandler);
        // Clear timeout only if it was potentially set
        if (timeoutId) clearTimeout(timeoutId);
      };

      loadHandler = () => {
        const failed = imgElement.naturalWidth === 0;
        if (this._imageLoadFailed !== failed && this._currentSrcChecked === srcToCheck) {
          this._imageLoadFailed = failed;
        }
        removeListeners();
      };

      errorHandler = () => {
        console.error("Failed to load image; loading fallback");
        if (!this._imageLoadFailed && this._currentSrcChecked === srcToCheck) {
          this._imageLoadFailed = true;
        }
        removeListeners();
      };

      imgElement.addEventListener("load", loadHandler);
      imgElement.addEventListener("error", errorHandler);

      // *** CONDITIONAL TIMEOUT ***
      // Only set the safety timeout for non-lazy images
      if (this.imgLoading !== "lazy") {
        timeoutId = window.setTimeout(() => {
          timeoutId = undefined; // Clear the ID after execution
          // Check completion status *again* within the timeout
          if (!imgElement.complete) {
            if (!this._imageLoadFailed && this._currentSrcChecked === srcToCheck) {
              this._imageLoadFailed = true; // Assume failure if still not complete
            }
            removeListeners(); // Clean up if timeout triggered failure
          } else {
            // If it completed *after* listeners were added but *before* timeout fired
            const failed = imgElement.naturalWidth === 0;
            if (this._imageLoadFailed !== failed && this._currentSrcChecked === srcToCheck) {
              this._imageLoadFailed = failed;
            }
            // Listeners would have already been removed by load/error handlers usually,
            // but call removeListeners() again for safety in edge cases.
            removeListeners();
          }
        }, 8000); // 8 seconds timeout only for eager loading
      }
    }
  }

  // Helper function to build common image parameters
  private buildImageParams(): string {
    return `?ef=${this.effort}&q=${this.quality}&fit=${this.imgFit}&sh=${this.sh}${this.imgWidth ? `&w=${this.imgWidth}` : ""}${this.imgHeight ? `&h=${this.imgHeight}` : ""}${this.imgSubdomain ? `&sbd=${this.imgSubdomain}` : ""}`;
  }

  // Helper to generate srcset string for a given format
  private generateSrcset(baseSrc: string, params: string, format: string): string {
    const dprs = this.imgDprs
      .split(",")
      .map(Number)
      .filter((n) => !isNaN(n) && n > 0);
    return dprs.map((dpr) => `${baseSrc}${params}&fmt=${format}&dpr=${dpr} ${dpr}x`).join(", ");
  }

  protected render() {
    const baseSrc = this.imgCDN + this.imgSrc;
    const params = this.buildImageParams();
    const altText = this.imgAlt || `${this.brand} ${this.model}`;

    // Use styleMap for inline styles
    const imgStyles = styleMap({
      "--img-fit": this.imgFit,
      "--img-ratio": this.imgRatio,
    });

    // Adjust fetchpriority based on loading attribute
    const fetchPriorityValue = this.imgLoading === "eager" ? "high" : this.imgPriority || "low"; // Allow override via imgPriority

    return html`
      ${when(
        this._imageLoadFailed,
        () => html` <img ${ref(this.imgRef)} part="img fallback-img" class="fallback-img" src="${FALLBACK_PLACEHOLDER_SRC}" alt="${altText}" width="${ifDefined(this.imgWidth || undefined)}" height="${ifDefined(this.imgHeight || undefined)}" style=${imgStyles} /> `,
        () => {
          const finalImgSrc = `${baseSrc}${params}&fmt=jpeg`; // Fallback src for img tag
          return html`
            <picture class="bui-picture">
              <source srcset="${this.generateSrcset(baseSrc, params, "avif")}" type="image/avif" />
              <source srcset="${this.generateSrcset(baseSrc, params, "webp")}" type="image/webp" />
              <source srcset="${this.generateSrcset(baseSrc, params, "jpeg")}" type="image/jpeg" />
              <img ${ref(this.imgRef)} part="img" class="bui-picture__img" width="${ifDefined(this.imgWidth || undefined)}" height="${ifDefined(this.imgHeight || undefined)}" loading="${this.imgLoading}" decoding="${this.imgLoading === "lazy" ? "async" : "sync"}" fetchpriority="${fetchPriorityValue}" src="${finalImgSrc}" alt="${altText}" style=${imgStyles} />
            </picture>
          `;
        }
      )}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "bui-picture": BuiPicture;
  }
}
